export default class Storer {

    static store(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value))
    }

    static addToStoredArray(key, value) {
        const arr = this.retrieve(key) ?? []
        const duplicateIndex = arr.findIndex(item => Object.keys(value).reduce((isDuplicate, key) => {
            if (!isDuplicate || key === 'quantity') return isDuplicate
            if (Array.isArray(item[key])) {
                console.log('Is Array', item[key], value[key])
                isDuplicate = this.compareArrays(item[key], value[key]) ? isDuplicate : false
            } else {
                isDuplicate = item[key] === value[key] ? isDuplicate : false
            }
            return isDuplicate
        }, true)
        )
        if (duplicateIndex >= 0) {
            arr[duplicateIndex].quantity += value.quantity
        } else {
            arr.push(value)
        }

        this.store(key, arr)

        this.itemsChanged(key)
    }

    static itemsChanged(checkoutName) {
        const checkout = this.retrieve(checkoutName)
        if (checkout) {
            checkout['uuid'] = ''
        }
        this.store(checkoutName, checkout)
    }

    static compareArrays(a, b) {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }

    static retrieve(key) {
        return JSON.parse(window.localStorage.getItem(key))
    }

    static delete(key) {
        window.localStorage.removeItem(key)
    }
}